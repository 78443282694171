.header-box {
    position: absolute;
    display: flex;
    margin-top: -24px;
    z-index: 0;
    width: 224px;
    height: 80px;
}

@media (max-width: 769px) {
    .header-box {
        height: 64px;
    }
}

.left {
    margin-left: 48px;
}

.center {
    left: 50%;
    transform: translate(-50%, 0);
}

.right {
    right: 0;
    margin-right: 100px;
}

.header-box-text {
    text-align: left;
    font-weight: 500;
    font-size: 24px;
    letter-spacing: 0;
    color: var(--white);
    opacity: 1;
    margin: auto;
}

.who-we-are-center {
    color: var(--white);
}

.bg-color-skyBlue {
    background-color: var(--sky-blue);
}

.bg-color-lightBlue {
    background-color: var(--light-blue);
}

.bg-color-blue {
    background-color: var(--blue);
}

.bg-color-darkGray {
    background-color: var(--dark-gray);
}