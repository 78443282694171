.nav-bar-container {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 60px;
    justify-content: space-between;
    overflow: hidden;
    /* background-color: aliceblue; */
    background: var(--white) 0% 0% no-repeat padding-box;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
    z-index: 3;
}

.logo-link-container {
    border: none;
    background-color: transparent;
    outline: none;
    margin: auto 0 auto 2%;
    height: 100%;
}

.header-logo {
    display: flex;
    cursor: pointer;
    height: 100%;
}

.navbar-buttons-container {
    margin: auto 150px auto 0;
    outline: none;
}

.mobile-navbar-buttons-container {
    outline: none;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.navbar-button {
    margin: 0 24px;
    color: var(--dark-gray);
    text-decoration: none;
    cursor: pointer;
    font-size: 15px;
    text-transform: uppercase;
}

.contact-container-animated {
	background-color: var(--light-blue);
	cursor: pointer;
	display: inline-block;
	padding: 10px 15px;
}

.contact-container {
    background-color: var(--light-blue);
    cursor: pointer;
    display: inline-block;
    padding: 10px 15px;
}

@media (max-width: 480px) {
	.contact-container, .contact-container-animated {
		padding: 6px 8px;
	}
	
	.navbar-button {
		margin: 0;
		font-size: 12px;
		text-transform: uppercase;
	}
}

@media (max-width: 440px) {
    .block-logo-container {
        margin: 12px 0 auto 24px;
    }
}

.contact-container .navbar-button {
    color: var(--white);
    margin: 0;
}

.contact-container-animated .navbar-button {
	color: var(--white);
	margin: 0;
}

.floating-contact-btn-container {
	position: fixed;
	top: 0;
	right: 0;
	display: flex;
	flex-direction: row;
	height: 60px;
	padding: 1px;
	justify-content: flex-end;
	align-items: center;
	overflow: hidden;
	z-index: 3;
	transition: right ease-in-out 0.58s;
}

.contact-container-animated:before,
.contact-container-animated:after
{
	content: '';
	border: 4px solid var(--light-blue);
	display: block;
	position: absolute;
	top: 9px;
	bottom: 9px;
	left: 0;
	right: 0;
	animation: pulse 1.3s ease-in-out infinite;
	opacity: 0;
	backface-visibility: hidden;
}

@media(max-width: 480px) {
	.contact-container-animated:before,
	.contact-container-animated:after
	{
		top: 13px;
		bottom: 13px;
	}
}

.contact-container:after
{
	animation-delay: .5s;
}

@keyframes pulse {
	0%
	{
		opacity: 0;
	}

	20%
	{
		opacity: .25;
	}

	40%
	{
		opacity: .5;
	}

	60%
	{
		opacity: .25;
	}

	80%
	{
		opacity: 0;
	}
}
