/* TODO make animations from this template */


/* @media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
} */


/* @keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
} */

.container {
    background-attachment: fixed;
    height: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 36px;
}

.section-title {
    display: flex;
    justify-content: center;
    text-align: center;
    color: var(--dark-gray);
    font-size: 3em;
    font-weight: normal;
}

.text-box {
    text-align: left;
    font-size: 18px;
    line-height: 1.6em;
    font-weight: 300;
    letter-spacing: 0;
    color: #707070;
    opacity: 1;
    margin: 0 16px;
}

@media(max-width: 900px) {
    .text-box {
        padding: 20px 0;
    }
}