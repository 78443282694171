.footer-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
    justify-content: center;
	padding: 40px 0;
	background-color: var(--smoke);
}

.menu-container {
	display: flex;
	flex-direction: column;
	margin-right: 200px;
	margin-left: 20px;
}

@media (max-width: 1056px) {
	.footer-container {
		flex-direction: column;
	}

	.menu-container {
		text-align: center;
		margin: 0 0 10px 0;
	}
}

.menu-button {
	font-weight: 500;
	font-size: 18px;
	line-height: 28px;
	color: var(--blue);
	cursor: pointer;
	font-weight: 800;
}

.menu-button:hover {
	text-decoration: underline;
}

.logo-info {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.logo-container {
	margin: 0 20px;
}

@media(max-width: 480px) {
	.logo-container {
		text-align: center;
	}
}

.logo-info-text {
	margin: 0 auto;
	font-size: 14px;
	line-height: 23px;
	font-weight: 800;
	color: var(--blue);
}
