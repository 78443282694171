.background {
    height: 520px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
}

.main-text-container {
    height: 80%;
    display: flex;
    align-items: center;
}

.main-text {
    font-style: normal;
    font-weight: normal;
    font-size: 3.5vw;
    line-height: 47px;
    color: var(--dark-gray);
}

@media(max-width: 769px) {
    .main-text {
        font-size: 10vw;
        text-align: center;
    }
    .link-container {
        justify-content: center !important;
        padding-right: 0 !important;
    }
    .special-word-container {
        margin: 28px 20vw;
    }
}

@media(max-width: 480px) {
    .main-text-container .main-text {
        text-align: left;
        margin-left: 15vw;
        font-size: 12vw;
    }
    .special-word-container {
        margin: 10px 0;
        margin-right: 12vw;
    }
}

.special-word-container {
    display: inline-block;
    text-align: center;
    color: var(--blue);
    min-width: 22.5vw;
}

.show-more-text {
    font-style: normal;
    font-weight: normal;
    font-size: 1.7em;
    line-height: 33px;
    color: var(--dark-gray);
    cursor: pointer;
    transition: font-size 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.show-more-text:hover {
    font-size: 2em;
}

.show-more-icon {
    margin: 4px 0 0 6px;
    cursor: pointer;
}

.link-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 8%;
}

.link-container a {
    display: flex;
    align-items: center;
}