.techs-section {
    margin-top: 92px;
    margin-bottom: 0;
}

.container {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(4, 238px);
    gap: 48px;
    margin-bottom: 92px;
    height: 550px;
}

.square {
    width: 238px;
    height: 230px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: box-shadow 0.4s, transform 0.4s;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.square:hover {
    transform: scale(1.08);
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

@media (min-width: 600px) and (max-width: 1280px) {
    .container {
        grid-template-columns: repeat(2, 238px);
        gap: 72px;
        height: 1200px;
    }
}

@media (max-width: 599px) {
    .container {
        grid-template-columns: 238px;
        height: 2400px;
    }
}

.square-text {
    color: var(--white);
    font-size: 2em;
    max-width: 200px;
}