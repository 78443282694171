.contact-section-container {
    padding-bottom: 60px;
}

.contact-section {
    margin-top: 92px;
    margin-bottom: 36px;
}

.contact-form {
    display: flex;
    max-width: 1180px;
    flex-wrap: wrap;
    margin: 0 auto;
    color: var(--white);
}

.call-us-section {
    justify-content: flex-start;
    text-align: left;
    margin: 36px 0 36px 10%;
}

@media(max-width: 769px) {
    .call-us-section {
        justify-content: center;
        margin: 36px 0;
    }
}

.info-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.info-box {
    background-color: var(--light-blue);
    border: none;
    text-align: center;
    padding: 1.6rem 1.9rem;
    margin: 0 10px;
    max-width: 100%;
    cursor: pointer;
    transition: transform ease-in-out 0.6s;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.info-box:hover {
    transform: scale(1.1);
}

.info-box-icon {
    color: var(--white);
    cursor: pointer;
}

.info-box input, .info-box span {
    padding-left: 6px;
    text-align: center;
    background-color: transparent;
    border: none;
    color: var(--white);
    font-size: 1.5rem;
    font-weight: 400;
    outline: none;
    cursor: pointer;
    transition: width ease-in-out 0.3s, font-weight step-start 3s;
}

@media(max-width: 540px) {
    .info-box input, .info-box span {
        font-size: 1.2rem;
    }
}

@media(max-width: 460px) {
    .info-box input, .info-box span {
        font-size: 1rem;
    }
}

.info-box:hover input, .info-box span {
    font-weight: 600;
}

@media (max-width: 1279px) {
    .info-container {
        flex-direction: column;
    }
    .info-box {
        margin-bottom: 32px;
        align-self: center;
        font-size: 30px;
    }
}